.themeContainer {
    position: fixed !important;
    right: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 99999;
    border-color: var(--border-color);
    padding: 0 !important;
    background-color: rgba(0, 0, 0, 0) !important;
}

.themeContainerNavbar {
    margin-left: auto;
    margin-right: 15px;
    padding: 0 !important;
    border-color: var(--border-color);
    background-color: rgba(0, 0, 0, 0) !important;
    z-index: 1;
}