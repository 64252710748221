.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  position: relative;
  bottom: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  --swiper-pagination-color: $heading-color;

}






.swiper-pagination-bullet {
  position: relative;
  width: 20px;
  height: 2px;
  border-radius: 0;
  margin-right: 5px;
  cursor: pointer;
  background: $heading-color;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: transform 0.3s ease-in-out;
  &.swiper-pagination-bullet-active{
    width: 30px;
  }

    &:before {
      content: '';
      position: absolute;
      display: block;
      top: -15px;
      left: -1px;
      right: -1px;
      bottom: -15px;
    }

    // &:hover {
    //   color: red;
    //   background-color: red;
    // }
}
